import Image from 'next/image';

const IMAGES_LIST = [
  '/images/aftermarket-logos/1.png',
  '/images/aftermarket-logos/2.png',
  '/images/aftermarket-logos/4.png',
  '/images/aftermarket-logos/6.png',
  '/images/aftermarket-logos/7.png',
  '/images/aftermarket-logos/8.png',
  '/images/aftermarket-logos/9.png',
  '/images/aftermarket-logos/10.png',
  '/images/aftermarket-logos/11.png',
  '/images/aftermarket-logos/12.png',
];

export default function AfterMarketLogosBlock() {
  return (
    <div className="container mx-auto max-w-[800px] px-4">
      <div
        className="flex flex-wrap justify-center gap-4 items-center mt-8"
        style={{ filter: 'grayscale(100%)' }}
      >
        {IMAGES_LIST.map((src) => (
          <Image
            className="max-w-[60px]"
            key={src}
            width={60}
            height={40}
            src={src}
            alt=""
            sizes="100vw"
            style={{
              width: '100%',
              height: 'auto',
            }}
          />
        ))}
      </div>
    </div>
  );
}
