import React from 'react';
import cx from 'classnames';

import { ListInterface } from '@/types/components';

import styles from './ListBlock.module.scss';
import { List } from '.';

interface ListBlockInterface extends ListInterface {
  title: string;
}

const ListBlock = ({
  title,
  ...restProps
}: ListBlockInterface): JSX.Element => {
  return (
    <div className={styles.container}>
      <h1
        className={cx(
          styles.title,
          'text-4xl font-bold text-white leading-[44px]',
        )}
      >
        {title}
      </h1>
      <hr className="border-gold-50 my-6" />
      <List {...restProps} />
    </div>
  );
};

export default ListBlock;
