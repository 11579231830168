import React from 'react';
import cx from 'classnames';
import Image from 'next/image';

import {
  ListInterface,
  ListSectionDataInterface,
  PhotoDataInterface,
} from '@/types/components';

import BulletList from './BulletList';
import styles from './List.module.scss';
import Collapse from '../Collapse';

const List = ({ anschor, data, indexed }: ListInterface): JSX.Element => {
  return (
    <div className="grid gap-y-12">
      {data.map(({ title, sections, collapsible }, index) => {
        const listnumber = index + 1;

        const titleContext = !!title && (
          <div
            className={cx(
              'flex',
              indexed ? 'items-baseline gap-x-2 mb-2' : 'mb-[10px]',
              { 'pr-2': collapsible },
            )}
          >
            {anschor && (
              <span id={`${anschor}-${listnumber}`} className="anchor" />
            )}
            {indexed && (
              <span className="text-lg text-gold-200 font-bold">
                {listnumber}.
              </span>
            )}
            <span className="text-lg text-left font-medium text-white">
              {title}
            </span>
          </div>
        );

        const contentContext = sections.map(
          ({ type, data: sectionData, highlight }, contextIndex) => {
            switch (type) {
              case 'letter_list':
              case 'number_list':
              case 'bullet_list':
                return (
                  <BulletList
                    key={contextIndex}
                    type={type}
                    highlight={highlight}
                    data={sectionData as ListSectionDataInterface[]}
                  />
                );
              case 'text':
              case 'step':
                return (
                  <div
                    key={contextIndex}
                    className={cx('grid gap-y-2', { 'pr-5': collapsible })}
                  >
                    {(sectionData as ListSectionDataInterface[]).map(
                      (section, sectionsIndex) => (
                        <p
                          key={sectionsIndex}
                          className={cx(
                            highlight
                              ? 'font-medium text-lg text-[#F2F2F2]'
                              : 'text-base font-normal text-gray-800',
                          )}
                        >
                          {type === 'step' && `Step ${sectionsIndex + 1}: `}
                          {typeof section === 'string' ? section : section.text}
                        </p>
                      ),
                    )}
                  </div>
                );
              case 'photo':
                return (
                  !!(sectionData as PhotoDataInterface[])?.length && (
                    <div
                      key={contextIndex}
                      className={cx(
                        { [styles.imgContainer]: sectionData.length > 1 },
                        'grid gap-8 mt-[18px]',
                      )}
                    >
                      {(sectionData as PhotoDataInterface[]).map(
                        ({ name, alt, height = 288, width = 320 }) => (
                          <Image
                            key={name}
                            className="rounded w-full object-cover"
                            height={height}
                            width={width}
                            src={`/images/${name}`}
                            alt={alt}
                          />
                        ),
                      )}
                    </div>
                  )
                );
              case 'gap':
                return <div key={contextIndex} className="mb-6" />;
              default:
                return null;
            }
          },
        );

        return collapsible ? (
          <Collapse key={index} isOpen title={titleContext}>
            {contentContext}
          </Collapse>
        ) : (
          <div key={index}>
            {titleContext}
            {contentContext}
          </div>
        );
      })}
    </div>
  );
};

export default List;
