import React from 'react';

import { ListDataInterface } from '@/types/components';

import { ListBlock } from '../List';

const FaqPageSection = ({
  data,
}: {
  data: { hideInFaqPage: boolean }[];
}): JSX.Element => {
  return (
    <ListBlock
      title="Frequently asked questions"
      anschor="faq"
      indexed
      data={
        data
          .filter(({ hideInFaqPage }) => hideInFaqPage !== true)
          .map(
            ({ hideInFaqPage, ...allData }) => allData,
          ) as ListDataInterface[]
      }
    />
  );
};

export default FaqPageSection;
