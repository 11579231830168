import React from 'react';
import cx from 'classnames';

import { TextListInterface } from '@/types/components';

import { BlockIndicator } from '../Indicator';
import { TextLink } from '../Navigation';

const TextList = ({
  data,
  indexLocation,
  className,
  listWrapperClassName,
}: TextListInterface): JSX.Element => {
  const showIndicator = indexLocation !== 'none';
  const isIndexLocationTop = indexLocation === 'top';

  return (
    <div className={className}>
      {data.map(({ title, link, sections }, index) => (
        <div key={index} className={cx(listWrapperClassName, 'mt-8')}>
          <div
            className={cx({
              'grid gap-2 items-center grid-cols-[min-content_auto]':
                showIndicator && !isIndexLocationTop,
            })}
          >
            {showIndicator && <BlockIndicator index={index + 1} />}
            <p
              className={cx('text-white text-lg font-medium', {
                'mt-5': isIndexLocationTop,
              })}
            >
              {title}
              {link && (
                <TextLink
                  className={link.className}
                  url={link.url}
                  testAttr={link.testAttr}
                >
                  {link.text}
                </TextLink>
              )}
            </p>
          </div>
          {sections
            ?.filter(({ type }) => type === 'text')
            .map(({ data: sectionData }, sectionIndex) =>
              sectionData.map((desc, index) => (
                <p
                  key={`${sectionIndex}-${index}`}
                  className="text-base text-gray-50 font-normal mt-2"
                >
                  {desc}
                </p>
              )),
            )}
        </div>
      ))}
    </div>
  );
};

export default TextList;
