import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import Image from 'next/image';

import { CardInterface } from '@/types/components';
import { BUTTON_TYPES } from 'constant';
import useCart from '@/hooks/cart';

import styles from './Card.module.scss';
import Ribbon from '../Ribbon';
import PriceBox from '../PriceBox';
import Tag from '../Tag';
import Button from '../Button';
import Link from 'next/link';
import { ADDITIONAL_ROUTES } from '@/data/pages/routes';

const Card = ({
  id,
  ribbon,
  price,
  discount,
  title,
  description,
  photos,
  photo,
  isSuggested,
}: CardInterface): JSX.Element => {
  const { addItemToCart, loadingIds, isLoading } = useCart();

  const [isAddingToCart, setIsAddingToCart] = useState(false);

  const photoUrl = photos?.[0]?.url ?? photo;

  const handleAddToCart = () => {
    setIsAddingToCart(true);
    addItemToCart('accessory', 1, id);
  };

  useEffect(() => {
    if (!loadingIds.includes(id) && isAddingToCart) setIsAddingToCart(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingIds]);

  return (
    <div
      className={cx(
        styles.cardContainer,
        'rounded relative bg-white flex flex-col',
        { ['m-1 h-[95%]']: isSuggested },
      )}
    >
      <Link
        href={`${ADDITIONAL_ROUTES.accessory.url}/${id}`}
        className={cx('px-5 h-full mb-[66px] pb-[25px]', {
          'pt-[34px]': !ribbon,
        })}
      >
        {ribbon && (
          <div className="px-1">
            <Ribbon text={ribbon} />
          </div>
        )}
        <div className="h-[132px] justify-center flex">
          <Image
            loader={
              photoUrl ? () => `${photoUrl}?w=${224}&h=${132}` : undefined
            }
            className="mx-auto object-cover w-[unset] h-full"
            src={photoUrl}
            alt={`${title} image`}
            width={224}
            height={132}
          />
        </div>
        <div className="text-lg font-medium text-gray-400 mb-2">{title}</div>
        <div className="text-sm font-normal text-gray-500 mb-2 whitespace-nowrap overflow-hidden text-ellipsis">
          {description}
        </div>
        <div className="flex-1 flex flex-col justify-end">
          <PriceBox price={price} discount={discount} />
          <hr className="mb-2 mt-2 border-gray-700" />
          <div className="flex gap-[13px] flex-wrap">
            <Tag
              icon="check"
              title="Available"
              iconColor="text-gray-muted"
              color="text-lime-700"
            />
            <Tag
              icon="check"
              title="VAT"
              iconColor="text-gray-muted"
              color="text-lime-700"
            />
            <Tag
              icon="add"
              title="Shipping"
              iconColor="text-gray-muted"
              color="text-gray-muted"
              underlined
            />
          </div>
        </div>
      </Link>
      <div className="px-5 pb-5 absolute bottom-0 w-full">
        <Button
          type={BUTTON_TYPES.FILLED}
          onClick={handleAddToCart}
          className="w-full add-to-cart-btn"
          loading={isAddingToCart}
          disabled={isLoading}
        >
          Add To Cart
        </Button>
      </div>
    </div>
  );
};

export default Card;
