import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { fetch } from '@/utils';

import { BUTTON_TYPES } from 'constant';
import { AccessoriesDataInterface } from '@/types/api';
import { AccessoriesInterface } from '@/types/components';

import styles from './AccessoriesList.module.scss';
import Button from '../Button';
import Card from './Card';

const AccessoriesList = ({
  hasMore,
  items,
}: AccessoriesInterface): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [cardData, setCardData] = useState(items);
  const [hasMoreData, setHasMoreData] = useState(hasMore);
  const [batchCount, setBatchCount] = useState(1);

  useEffect(() => {
    if (isLoading) {
      fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/accessories?batch=${
          batchCount + 1
        }`,
      )
        .then((res) => res.json())
        .then((data: AccessoriesDataInterface) => {
          setCardData((oldData) => [...oldData, ...data.items]);
          setHasMoreData(data.hasMore);
          setBatchCount((oldBatchCount) => oldBatchCount + 1);
        })
        .finally(() => setIsLoading(false));
    }
  }, [isLoading, batchCount]);

  return (
    <div className={cx(styles.container, 'bg-white')}>
      <h2 className={cx(styles.title, 'text-gray-200 text-4xl font-bold')}>
        Accessories
      </h2>
      <div className={cx(styles.itemsContainer, 'my-12 grid gap-y-12 gap-x-6')}>
        {cardData.map(({ id, price, discountedPrice, ...restCardData }) => (
          <Card
            {...restCardData}
            key={id}
            id={id}
            ribbon={
              discountedPrice
                ? `${Math.floor(100 - (discountedPrice * 100) / price)}% off`
                : undefined
            }
            price={price}
            discount={discountedPrice}
          />
        ))}
      </div>
      {hasMoreData && (
        <Button
          onClick={() => setIsLoading(true)}
          type={BUTTON_TYPES.WIREFRAME}
          disabled={!hasMoreData}
          loading={isLoading}
        >
          Show More
        </Button>
      )}
    </div>
  );
};

export default AccessoriesList;
