import React from 'react';
import cx from 'classnames';

import { TextList } from '../List';
import styles from './HowToSection.module.scss';

const HowToSection = ({ data }: { data: any }): JSX.Element => {
  return (
    <div
      className={cx(styles.wrapper, 'flex flex-col items-start bg-brand-100')}
    >
      <div className="text-gold-50 text-base font-medium mb-2">
        Everything you need
      </div>
      <div className={cx(styles.listWrapper, 'grid gap-x-8')}>
        <div>
          <h2 className={cx(styles.title, 'text-white font-bold')}>
            How to find part number?
          </h2>
          <div className="font-normal text-lg text-gray-50 mt-4">
            Don&apos;t have the part number? You can find it in three ways:
          </div>
        </div>
        <div className={styles.partPurchaseTitle}>
          <h2 className={cx(styles.title, 'text-white font-bold')}>
            How to purchase parts?
          </h2>
        </div>
        <TextList indexLocation="top" data={data.findPartNumber} />
        <TextList indexLocation="left" data={data.purchaseParts} />
      </div>
    </div>
  );
};

export default HowToSection;
