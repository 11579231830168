import { resolvePageDataPath, fetch, getDomainsPaths } from '@/utils';
import AccessoriesList from '@/components/Accessories';
import AboutSection from '@/components/AboutSection';
import HeadSeo from '@/components/_App/HeadSeo';
import HowToSection from '@/components/HowToSection';
import HeroSlider from '@/components/HeroSlider';
import PartSearchBlock from '@/components/PartSearchBlock';
import { FaqHomepageSection } from '@/components/FaqSection';
import ReviewsSection from '@/components/ReviewsSection';
import AfterMarketLogosBlock from '@/components/AfterMarketLogosBlock';

import { HomeInterface } from '@/types/pages';

export default function Home({
  heroSlides,
  accessories,
  data,
  faqData,
  aboutData,
}: HomeInterface) {
  return (
    <div>
      <HeadSeo title={data.metaTitle} description={data.metaDescription} />
      <HeroSlider
        heroImage={data.heroImage}
        title={data.heroText}
        description={data.description}
        data={heroSlides}
      />
      <AfterMarketLogosBlock />
      <PartSearchBlock data={data} />
      <HowToSection data={data.howTo} />
      <AccessoriesList
        items={accessories?.items || []}
        hasMore={accessories?.hasMore || false}
      />
      <FaqHomepageSection data={faqData} />
      <ReviewsSection bgColor="bg-black" textColor="text-white" />
      <AboutSection data={aboutData} />
    </div>
  );
}

export async function getStaticPaths() {
  return {
    paths: await getDomainsPaths(),
    fallback: 'blocking',
  };
}

export async function getStaticProps({
  params: { origin },
}: {
  params: { origin: string };
}) {
  const [data, faqData, aboutData] = await Promise.all([
    await resolvePageDataPath('home', origin),
    await resolvePageDataPath('faq', origin),
    await resolvePageDataPath('about', origin),
  ]);

  const [resAccessories, res] = await Promise.all([
    await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/accessories?batch=1`,
      undefined,
      origin,
    ),
    await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/hero-slides`,
      undefined,
      origin,
    ),
  ]);

  const [accessories, heroSlides] = await Promise.all([
    await resAccessories.json(),
    await res.json(),
  ]);

  return {
    revalidate: 3600 * 24 * 30, // set revalidate interval of 30 days
    props: {
      data,
      faqData,
      aboutData,
      heroSlides: heroSlides?.data.length ? heroSlides.data : null,
      accessories: accessories ?? null,
    },
  };
}
