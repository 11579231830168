import React, { ReactNode } from 'react';
import cx from 'classnames';
import Image from 'next/image';

import styles from './AboutSection.module.scss';

const AboutSection = ({
  data,
}: {
  data: {
    title: string;
    header: string;
    description: string;
    whyOrder: { title: string; pointers: { description: any }[] };
  };
}): JSX.Element => {
  return (
    <div className={cx(styles.wrapper, 'flex justify-center bg-brown-50')}>
      <div className={styles.container}>
        <div className="border-gold-50 border-y-2 py-6">
          <h3 className="text-4xl font-bold text-white text-center">
            {data.header}
          </h3>
          <h4 className="text-center text-white text-[32px] font-normal mt-6">
            {data.title}
          </h4>
          <p className="mt-6 text-white text-lg font-normal text-center">
            {data.description}
          </p>
        </div>
        <div>
          <h3 className="text-4xl font-bold text-white py-8">
            {data.whyOrder.title}
          </h3>
          <div>
            <ul className="flex flex-col gap-y-1">
              {data.whyOrder.pointers?.map((description, index: number) => (
                <li
                  className="flex items-baseline"
                  key={`description-${index}`}
                >
                  <Image
                    width={14}
                    height={10}
                    src="/icons/checkmark.svg"
                    alt="Checkmark icon"
                    className="mr-4 h-[14px] w-[10px]"
                  />
                  <p className="text-white text-lg font-bold">
                    {description as any}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
