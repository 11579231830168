import React from 'react';
import Image from 'next/image';
import cx from 'classnames';

import { TagInterface } from '@/types/components';

const Tag = ({
  icon,
  title,
  color,
  iconColor,
  underlined,
}: TagInterface): JSX.Element => {
  const textColor = color ?? 'text-gray-600';

  return (
    <div className="h-[18px] flex gap-1 items-center">
      <div className="h-[18px] w-[18px] flex items-center justify-center">
        <Image
          className={iconColor}
          width={8}
          height={8}
          src={`/icons/${icon}.svg`}
          alt={`${title} icon`}
        />
      </div>
      <span
        className={cx('text-sm font-medium', textColor, {
          underline: underlined,
        })}
      >
        {title}
      </span>
    </div>
  );
};

export default Tag;
