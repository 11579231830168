import React from 'react';
import Image from 'next/image';

import Divider from '../Divider';
import StarSection from './StarSection';

interface IReviewCard {
  rating: number;
  message: string;
  user: string;
  title: string;
}

const ReviewCard = ({ rating, message, user, title }: IReviewCard) => {
  return (
    <div className="p-5 shadow-sm bg-white">
      <div className="flex gap-[8px] flex-wrap">
        <StarSection rating={rating} />
        <p>
          <b>{rating}</b> out of 5
        </p>
      </div>
      <h1 className="text-brand-100 font-medium text-lg mt-2">{title}</h1>
      <p className="my-2">{message}</p>
      <Divider className="w-[60px] bg-gray-400" />
      <div className="flex mt-2 gap-[8px]">
        <Image
          src="/icons/circle-check-green.svg"
          alt="check success"
          height={20}
          width={20}
        />
        <p className="font-bold text-green-800">Reviewed by {user}</p>
      </div>
    </div>
  );
};

export default ReviewCard;
