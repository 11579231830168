import React, { Fragment } from 'react';
import cx from 'classnames';

import { BulletListInterface } from '@/types/components';

const BulletList = ({
  type,
  data,
  highlight,
}: BulletListInterface): JSX.Element => {
  return (
    <ol
      className={cx(
        'pl-6',
        { 'list-alpha': type === 'letter_list' },
        { 'list-decimal': type === 'number_list' },
        { 'list-disc': type === 'bullet_list' }
      )}
    >
      {data.map((line, index) => (
        <Fragment key={index}>
          <li
            className={cx(
              highlight
                ? 'font-medium text-lg text-[#F2F2F2]'
                : 'text-base font-normal text-gray-800'
            )}
          >
            {typeof line === 'string' ? line : line.text}
          </li>
          {typeof line !== 'string' && (
            <div className='pl-2'>
              <BulletList type={line.type} data={line.data} />
            </div>
          )}
        </Fragment>
      ))}
    </ol>
  );
};

export default BulletList;
