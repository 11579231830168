import React from 'react';
import cx from 'classnames';
import Link from 'next/link';

import { TextListDataInterface } from '@/types/components';
import { BUTTON_TYPES } from '@/constant/Button';
import { ADDITIONAL_ROUTES } from '@/data/pages/routes';

import { TextList } from '../List';
import styles from './FaqHomepageSection.module.scss';
import Button from '../Button';

const FaqHomepageSection = ({
  data,
}: {
  data: { showInHomepage: boolean }[];
}): JSX.Element => {
  return (
    <div className={cx(styles.container, 'bg-brand-100')}>
      <h2 className="text-[32px] text-white font-bold border-gold-50 border-b-2 pb-8 mb-2">
        Frequently asked questions
      </h2>
      <TextList
        indexLocation="none"
        data={
          data.filter(
            ({ showInHomepage }) => showInHomepage,
          ) as TextListDataInterface[]
        }
        className={cx(styles.faqList, 'grid grid-cols-2 gap-x-12')}
        listWrapperClassName={styles.listWrapper}
      />
      <div className="text-right mt-8">
        <Link href={ADDITIONAL_ROUTES.faq.url}>
          <Button type={BUTTON_TYPES.FILLED} onClick={() => null}>
            Show More
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default FaqHomepageSection;
