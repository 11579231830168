import React, { FormEvent, useRef, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import cx from 'classnames';
import { event } from 'nextjs-google-analytics';

import { fetch, shouldShowCatalog } from '@/utils';
import { validatePartNumber } from '@/utils';
import Loader from '@/components/Loader';
import { ADDITIONAL_ROUTES } from '@/data/pages/routes';

import styles from './PartSearchBlock.module.scss';

const PartSearchBlock = ({ data }: { data: any }) => {
  const partInputRef = useRef(null);

  const router = useRouter();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [partNumber, setPartNumber] = useState<string | undefined>();

  const isMercedes = router.query.origin === 'oemstar.co.uk';

  const search = async () => {
    setError(false);

    fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/search?part_number=${partNumber}`,
      {
        headers: { accept: 'application/json' },
      },
    )
      .then((resp) => {
        if (!resp.ok) throw resp;

        event('SearchPart', {
          category: 'Bottom',
          label: 'Success',
        });

        return resp.json();
      })
      .then((part) => {
        router.push(`/part/${part.partNumber}`);
      })
      .catch((error) => {
        if (partNumber?.length === 17) {
          router.push({
            query: {
              vin: partNumber,
            },
            pathname: '/catalog',
          });
        } else {
          error?.json().then(() => setError(true));

          event('SearchPart', {
            category: 'Bottom',
            label: 'Fail',
          });

          setLoading(false);
        }
      });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (loading && !validatePartNumber(partNumber)) return;
    if (validatePartNumber(partNumber)) {
      setLoading(true);
      search();
    }
  };

  return (
    <>
      <div className={styles.container} id="search-part">
        <div className={styles.content}>
          <div className={styles.formBox}>
            <div className="mb-12">
              <div className="text-light-50 text-4xl leading-[44px] text-center font-bold mb-4">
                Search by part number
              </div>
              <div className="text-white font-normal text-lg text-center">
                {data.searchDescription}
              </div>
            </div>
            <div className={styles.responsiveContainer}>
              <div
                ref={partInputRef}
                className={cx(styles.searchBox, 'relative')}
              >
                <form className="w-full" onSubmit={handleSubmit}>
                  <input
                    value={partNumber}
                    onChange={(e) => setPartNumber(e.target.value)}
                    type="text"
                    maxLength={20}
                    name="part-number-search-main"
                    id="part-number-search-main"
                    className={cx(
                      styles.searchInput,
                      'block w-full bg-brand-50 rounded text-base font-normal pr-[30%] transition-colors focus:ring-gold-100 hover:ring-[0.5px] hover:ring-gold-hover',
                    )}
                    placeholder={data.searchInputPlaceholder}
                  />
                </form>
              </div>
              <button
                className={cx(styles.searchButton, 'transition-colors ', {
                  [styles.disabledBtn]: loading,
                })}
                onClick={() => {
                  if (validatePartNumber(partNumber)) {
                    setLoading(true);
                    search();
                  }
                }}
                disabled={loading && !validatePartNumber(partNumber)}
              >
                Search
              </button>
            </div>
            {error && !loading && (
              <div className="pb-[64px] pt-4 w-full">
                <div className="text-white font-normal text-base text-left w-full">
                  <div className="text-2xl">No search results found</div>
                  <ul className="mt-4 list-disc ml-3">
                    {shouldShowCatalog(router.query.origin) ? (
                      <li>
                        Find the correct part number by using our{' '}
                        <Link href="/#search-catalog" className="underline">
                          catalog
                        </Link>
                      </li>
                    ) : (
                      <li>
                        Make sure the part number is correct, by using our{' '}
                        <Link
                          href={ADDITIONAL_ROUTES.searchByVin.url}
                          className="underline"
                        >
                          FAQ
                        </Link>
                      </li>
                    )}
                    <li className="my-2">
                      Contact us for assistance at{' '}
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        className="underline"
                        href="mailto:parts@oemstock.co.uk"
                      >
                        parts@oemstock.co.uk
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            )}
            {loading && (
              <div className={styles.loaderContainer}>
                <Loader />
              </div>
            )}
            {shouldShowCatalog(router.query.origin) ? (
              <>
                <div
                  id="search-catalog"
                  className="text-light-50 text-4xl leading-[44px] text-center font-bold mb-4 mt-[48px]"
                >
                  Search parts in catalog
                </div>
                <p className="text-white font-normal text-lg text-center text-center w-full mb-[36px]">
                  Unsure of part number? Find it in the parts catalog
                </p>
                <div className="flex gap-[48px]">
                  <a
                    href="/catalog"
                    className="flex gap-2 justify-center items-center py-[14px] px-[28px] bg-[#947132] rounded-[6px] font-medium text-base text-[#ffffff] hover:bg-[#765a28]"
                  >
                    <Image
                      width={16}
                      height={16}
                      src="/icons/car.svg"
                      alt="Car icon"
                    />
                    {isMercedes ? <p>Cars</p> : <p>Catalog</p>}
                  </a>
                  {isMercedes && (
                    <a
                      className="flex gap-2 justify-center items-center py-[14px] px-[28px] bg-[#947132] rounded-[6px] font-medium text-base text-[#ffffff] hover:bg-[#765a28]"
                      href="/catalog"
                    >
                      <Image
                        width={18}
                        height={18}
                        src="/icons/truck.svg"
                        alt="Truck icon"
                      />
                      <p>Truck|Bus</p>
                    </a>
                  )}
                </div>
              </>
            ) : (
              <Link
                href={ADDITIONAL_ROUTES.searchByVin.url}
                className="text-sm underline font-medium text-light-50 text-left w-full"
                data-cy="no-part-number-button"
              >
                Don’t have a part number? Click here
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PartSearchBlock;
