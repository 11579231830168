import React from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { event } from 'nextjs-google-analytics';
import cx from 'classnames';

import * as data from '@/data/json/reviews/reviews.json';
import { BREAKPOINTS } from '@/constant/Swiper';

import ReviewCard from './ReviewCard';

interface IReviewsSection {
  bgColor?: string;
  textColor?: string;
}

function ReviewsSection({
  bgColor = 'bg-white',
  textColor = 'text-black',
}: IReviewsSection) {
  const seeMoreReviews = () => {
    event('Click', {
      category: 'Reviews',
      label: 'See more',
    });
  };

  return (
    <div
      className={cx(
        'px-[30px] md:px-[112px] pt-[120px] pb-4 col-span-2',
        bgColor,
      )}
      id="reviews-section"
    >
      <h1 className={cx('text-4xl font-bold mb-2', textColor)}>
        What customers say about us
      </h1>
      <p className={cx(textColor)}>
        We do our best to provide the best experience ever
      </p>
      <div className="flex pt-8 relative">
        <Swiper
          slidesPerView={4}
          spaceBetween={10}
          modules={[Pagination]}
          pagination={{
            clickable: true,
            dynamicMainBullets: 4,
          }}
          breakpoints={BREAKPOINTS}
        >
          {Array.from(data).map(({ message, user, rating, title }, index) => (
            <SwiperSlide key={`${title}_${index}`}>
              <ReviewCard
                message={message}
                user={user}
                rating={rating}
                title={title}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <button
        onClick={seeMoreReviews}
        style={{ backgroundColor: '#684f23' }}
        className={cx(
          'p-[11px] rounded-[4px] font-medium cursor-pointer text-white',
        )}
      >
        See more
      </button>
    </div>
  );
}

export default ReviewsSection;
