import React, { ReactNode } from 'react';
import Link from 'next/link';
import cx from 'classnames';

interface TextInterface {
  url: string;
  children: ReactNode;
  className?: string;
  testAttr?: string;
}

const Text = ({
  url,
  children,
  className,
  testAttr,
}: TextInterface): JSX.Element => {
  return (
    <Link
      href={url}
      className={cx(className, 'underline text-gold-50')}
      data-cy={testAttr}
    >
      {children}
    </Link>
  );
};

export default Text;
