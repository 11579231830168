import React from "react";

import { BlockInterface } from "@/types/components";

const Block = ({ index }: BlockInterface): JSX.Element => {
  return (
    <div className="bg-gray-100 py-2 text-center rounded w-12 h-12">
      <span className="text-2xl font-bold text-gold-200">
        {index < 10 ? `0${index}` : index}
      </span>
    </div>
  );
};

export default Block;
