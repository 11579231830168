import React, { useEffect, useRef, useState } from "react";
import Image from "next/image";
import cx from "classnames";

import { CollapseInterface } from "@/types/components";

const Collapse = ({
  title,
  children,
  isOpen: isOpened,
}: CollapseInterface): JSX.Element => {
  const childrenRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(isOpened);
  const [isLoaded, setIsLoaded] = useState(false);
  const [height, setHeight] = useState(0);

  const handleResize = () => {
    setHeight(childrenRef.current?.scrollHeight || 0);
  };

  useEffect(() => {
    if (isOpened) setIsOpen(isOpened);
  }, [isOpened]);

  useEffect(() => {
    if (childrenRef.current?.scrollHeight) {
      setIsLoaded(true);
      setHeight(childrenRef.current?.scrollHeight);
    }
  }, [childrenRef.current?.scrollHeight]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <button
        onClick={() => setIsOpen((oldValue) => !oldValue)}
        className="w-full flex items-center justify-between"
      >
        {title}
        <Image
          width={14}
          height={7}
          src="/icons/arrowUp.svg"
          alt="arrow up icon"
          className={cx("transition-[transform] ease-in-out", {
            "rotate-180": !isOpen,
          })}
        />
      </button>
      <div
        ref={childrenRef}
        className={cx("overflow-hidden transition-[max-height] ease-in-out", {
          "max-h-0": isLoaded || (!isLoaded && !isOpened),
        })}
        style={{
          maxHeight: (isOpen && height) || undefined,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Collapse;
